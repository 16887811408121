import React from 'react'
import styled from 'styled-components'
import {
  HEADER_HEIGHT,
  SHADOW_LIGHT,
  GREY_MEDIUM_LIGHT,
  PRIMARY_DARK,
  PRIMARY,
  PRIMARY_LIGHT,
  BG_PRIMARY,
} from '../constants/cssVars'
import { Menu } from './Menu'
import { Logos } from './LoggedOutHeader'

const HeaderWrapper = styled.div`
  width: 100%;
  position: fixed;
  height: ${HEADER_HEIGHT}px;
  // background-color: #fff;
  color: ${PRIMARY_DARK};
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0px;
  left: 0px;
  z-index: 10;
  box-shadow: ${SHADOW_LIGHT};
  border-bottom: 1px solid ${GREY_MEDIUM_LIGHT};
  background-image: radial-gradient(
    circle at right,
    ${PRIMARY_DARK},
    ${PRIMARY_DARK}
  );
`

const HeaderContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const HeaderOffset = styled.div`
  width: 100%;
  height: ${HEADER_HEIGHT}px;
`

const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'Open Sans Condensed', sans-serif;
  font-size: 1.2em;
  width: 100%;
  > a {
    margin: 0px 15px;
    text-decoration: none;
    font-weight: 600;
    color: ${PRIMARY};
    &:hover {
      color: ${PRIMARY_LIGHT};
    }
  }
`

const MenuWrapper = styled.div`
  min-width: 280px;
  margin-right: 20px;
  display: flex;
  justify-content: flex-end;
  @media only screen and (max-width: 700px) {
    min-width: 0px;
  }
`
const HeaderTitle = styled.span`
  font-family: 'Open Sans Condensed', sans-serif;
  font-size: 1.2em;
  font-weight: 600;
  color: ${BG_PRIMARY};
  display: flex;
  align-items: center;
  margin-left: 100px; /* Adjust this value to increase or decrease the shift to the right */
  margin-right: 100px; /* Adjust this as needed to align with your layout preferences */
  &:hover {
    color: ${PRIMARY_LIGHT}; // Optionally keep or remove the hover effect since it's no longer a link
  }
`

export const Header = () => {
  return (
    <header>
      <HeaderWrapper>
        <HeaderContent>
          <Nav>
            <Logos />
            <HeaderTitle>TDISMeta</HeaderTitle>
            <MenuWrapper>
              <Menu />
            </MenuWrapper>
          </Nav>
        </HeaderContent>
      </HeaderWrapper>
      <HeaderOffset />
    </header>
  )
}
