import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useHistory, useParams } from 'react-router-dom';
import { clearStore } from '../actions/general';
import { logoutQuery } from '../actions/queries';
import { mutateAsync } from 'redux-query';
import { USER_STORAGE_KEY } from '../constants/general';
import { useCurrentUser } from '../hooks/useCurrentUser';

// Returns function to clear state and redirect to the homepage with an error message
// For use when an unauthorized status is found
export const useLogout = () => {
  const { accessToken } = useCurrentUser();
  const dispatch = useDispatch();
  const history = useHistory();

  return () => {
    // first, logout
    dispatch(clearStore());
    localStorage.removeItem(USER_STORAGE_KEY);
    toast.info("Logged out");
    history.push(`/login`);
  }
}
