import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { mutateAsync } from 'redux-query'
import { loginQuery } from '../../actions/queries'
import { statusIsGood } from '../../utils/helpers'
import { useCurrentUser } from '../useCurrentUser'
import { useSelectedRecord } from '../useSelectedRecord'

const TDIS_DATA_CATALOG_URL_BASE =
  process.env.REACT_APP_TDIS_DATA_CATALOG_URL_BASE ||
  'https://tdisproddatalakehouse.blob.core.windows.net/tdis-data-bronze'

const SAS_TOKEN = process.env.REACT_APP_UPLOAD_SAS_TOKEN || ''

const getBashScript = (bucket) => {
  return `#!/bin/bash
# Upload script for TDIS Ingest
# Author: Akhilesh Veerapareddy aveerapareddy@tamu.edu
# Last Update:  12/16/2021 12/29/2021 01/04/2022 01/26/2022 03/21/2022 03/09/2023
# Last Updated By: Akhilesh Veerapareddy
# The upload process is recursive from the directory provided as a parameter after the script, the script will try to upload one at
# time every file it finds within the starting directory.
# syntax is ./nix_upload.sh <<starting directory>>
# The script generates a report created in the run directory that is appended each time thereafter with the filename along with date and time of upload
###########

## ADLS Folder Name - TODO this should come from the script generated when you fill out the metadata along with user credentials for the upload
bucket=${bucket}


# Check if the first argument is provided and if it is a file
if [ -z "$1" ]; then
    echo "[Error] No file path argument provided. Please specify the path to the file."
    exit 1
elif [ ! -f "$1" ]; then
    echo "[Error] The specified path '$1' is not a file or does not exist."
    exit 1
fi

filePath=$1
fileName=$(basename "$filePath")
destinationPath="$HOME/azcopy"

echo "[Info] Starting AzCopy setup and file upload process..."
# Check if AzCopy is already installed
if [ -f "$destinationPath/azcopy" ]; then
    echo "[Info] AzCopy is already installed at: $destinationPath"
else
    # Download the latest AzCopy release for macOS
    echo "[Info] Downloading AzCopy..."
    initialUri='https://aka.ms/downloadazcopy-v10-mac'
    if curl -L $initialUri -o azcopy.tar.gz; then
        echo "[Success] Downloaded AzCopy successfully."
    else
        echo "[Error] Failed to download AzCopy."
        exit 1
    fi

    # Extract the AzCopy tarball
    echo "[Info] Extracting AzCopy files..."
    if mkdir -p $destinationPath && tar -xvf azcopy.tar.gz -C $destinationPath --strip-components=1; then
        echo "[Success] AzCopy has been extracted and installed at: $destinationPath"
    else
        echo "[Error] Failed to extract AzCopy."
        exit 1
    fi

    # Clean up the downloaded TAR file
    rm azcopy.tar.gz
fi

# Update the destination path to include the AzCopy binary
azCopyPath="$destinationPath/azcopy"

# Construct the destination URI using the provided storage account, container name, and SAS token
destinationUri="${TDIS_DATA_CATALOG_URL_BASE}/${bucket}/${SAS_TOKEN}"

# Use AzCopy to upload the file with the SAS token
if [ -f "$azCopyPath" ]; then
    echo "[Info] Uploading file $filePath to $destinationUri..."
    if "$azCopyPath" copy "$filePath" "$destinationUri" --recursive; then
        echo "[Success] File has been successfully uploaded to $destinationUri"
    else
        echo "[Error] Failed to upload file $filePath to $destinationUri."
    fi
else
    echo "[Error] AzCopy executable not found at: $azCopyPath"
fi

echo "[Info] AzCopy file upload process completed."

unset IFS; set +f`
}

export const useBashDownload = (recordId) => {
  const dispatch = useDispatch()
  const [selectedRecord] = useSelectedRecord()
  const { username } = useCurrentUser()
  // const password = useSelector(passwordSelector);
  const regionId =
    (selectedRecord
      ? selectedRecord.currentMetadata.twdbFloodPlanningRegionID
      : null) || '01'

  // Generate a UUID v4
  const uuid = ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  )

  // Concatenate the generated UUID with the bucket name
  const bucket = `twdb-region-${regionId}/${uuid}`

  const promptForPassword = () => {
    const passwordInput = prompt('Please re-enter your password', '')
    dispatch(
      mutateAsync(loginQuery({ username, password: passwordInput }))
    ).then(({ status }) => {
      if (statusIsGood(status)) {
        getFile(passwordInput)
      } else {
        toast.error('Incorrect password, please try again')
      }
    })
  }

  const getFile = (currentPassword) => {
    const exportedFilename = `upload_script.sh`
    var blob = new Blob([getBashScript(bucket)], {
      type: 'text/plain;charset=utf-8;',
    })
    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, exportedFilename)
    } else {
      var link = document.createElement('a')
      if (link.download !== undefined) {
        // feature detection
        // Browsers that support HTML5 download attribute
        var url = URL.createObjectURL(blob)
        link.setAttribute('href', url)
        link.setAttribute('download', exportedFilename)
        link.style.visibility = 'hidden'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
    }
  }
  return promptForPassword
}
