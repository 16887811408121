import React from 'react';
import styled from 'styled-components';
import { FOSSIL, MAIN_CONTENT_WIDTH, PRIMARY, SAGE, SHADOW_CRISP } from '../constants/cssVars';
import { useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import { MultiFieldLine, SelectField, TextField } from './FormFields';
import { Button } from './Button';
import { validateContact, validatePersonalContact, validateResetPassword } from '../utils/validators';
import { useResetPassword, useUpdateContactQuery } from '../hooks/messagesAndRequests';
import { useSelector } from 'react-redux';
import { useCurrentUser } from '../hooks/useCurrentUser';
import { useAgencyOptions } from '../constants/formOptions';
import { contactsByIdSelector } from '../selectors/entities';
import { toast } from 'react-toastify';
import { WaveLoader } from './Loaders';

const Wrapper = styled.div`
  padding-top: 20px;
  width: ${MAIN_CONTENT_WIDTH}px;
  @media only screen and (max-width: ${MAIN_CONTENT_WIDTH}px) {
    width: 100%;
  }
`;

export const BackButton = styled.button`
  background-color: transparent;
  border: none;
  > img {
    width: 40px;
    height: 30px;
  }
`

const Tile = styled.div`
  margin-bottom: 20px;
  width: 100%;
  background-color: #fff;
  box-shadow: ${SHADOW_CRISP};
  border: 1px solid ${FOSSIL};
  text-align: left;
  padding: 20px;
`

const Icon = styled.svg.attrs({ 
  version: '1.1', 
  xmlns: 'http://www.w3.org/2000/svg', 
  xmlnsXlink: 'http://www.w3.org/1999/xlink',
})``

const Svg = styled(Icon)` 
  width: 30px; 
  height: 30px; 
  &:hover {
    > path {
      fill: ${SAGE};
    }
  }
`

export const BackIcon = () => {
  return (
    <Svg viewBox="0 0 115 115">
      <path d="M38.1421358,0.372624131 L46.627417,8.85786438 C47.0179413,9.24838867 47.0179413,9.88155365 46.627417,10.2720779 L26.8986242,29.9990928 L99,30 C99.5128358,30 99.9355072,30.3860402 99.9932723,30.8833789 L100,31 L100,43 C100,43.5522847 99.5522847,44 99,44 L99,44 L26.8986242,43.9990928 L46.627417,63.7279221 C46.987901,64.088406 47.0156305,64.6556371 46.7106056,65.0479283 L46.627417,65.1421356 L38.1376869,73.6318657 C37.7489043,74.0206483 37.1191787,74.0226355 36.7279501,73.6363143 L36.7279501,73.6363143 L0.645002969,38.0059706 C0.630873553,37.9920184 0.617162847,37.9776485 0.603889088,37.9628799 C0.234706253,37.5521215 0.268409924,36.9198542 0.679168344,36.5506713 L0.679168344,36.5506713 L4.40062418,33.2050928 L36.7279632,0.38338922 C37.1155035,-0.0100964547 37.7486501,-0.0149161489 38.1421358,0.372624131 Z" 
      id="arrow" fill={PRIMARY}></path>
    </Svg>
  )
}

export const Settings = () => {
  const history = useHistory();
  return (
    <Wrapper>
      <BackButton onClick={() => history.push('/app')} ariaLabel="back"><BackIcon alt="back arrow" /></BackButton>
      <h2 style={{marginLeft: '10px', marginTop: '10px'}}>Settings</h2>
      <PasswordReset />
      <UpdatePersonalContact />
    </Wrapper>
  )
}

const PasswordReset = () => {
  const [submit, submitting] = useResetPassword();
  const { username } = useCurrentUser();

  const onSubmit = ({ oldPassword, newPassword }) => {
    submit({ oldPassword, newPassword, username })
  }
  
  return (
    <Formik
      validate={validateResetPassword}
      onSubmit={onSubmit}
      initialValues={{
        oldPassword: '',
        newPassword: '',
        newPasswordConfirmation: '',
      }}>
      {({ handleSubmit, errors, touched, submitCount }) => {
        const getError = (name) => (touched[name] || submitCount >= 1) && errors[name];
        return (
          <Tile>
            <div style={{ display: 'flex', justifyContent: 'space-between', paddingTop: '10px', alignItems: 'flex-start' }}>
              <h3 style={{marginTop: '0px'}}>Password Reset</h3>
            </div>
            <div style={{maxWidth: '400px'}}>
              <TextField label="Current password*" error={getError('oldPassword')}  style={{ margin: '5px 0px '}} type="password" name="oldPassword" />
              <TextField label="New password*" error={getError('newPassword')}  style={{ margin: '5px 0px '}} type="password" name="newPassword" />
              <TextField label="Confirm new password*" error={getError('newPasswordConfirmation')}  style={{ margin: '5px 0px '}} type="password" name="newPasswordConfirmation" />
            </div>
            <Button type='btn' onClick={handleSubmit} isLoading={submitting} wrapperStyle={{marginTop: '20px'}}>
              Reset Password
            </Button>
          </Tile>
      )}}
    </Formik>
  )
}

const UpdatePersonalContact = () => {
  const [agencyOptions, getAgencyOptionFromValue, agencyQueryFinished] = useAgencyOptions();
  const { id } = useCurrentUser();
  const contactsById = useSelector(contactsByIdSelector);
  const contact = contactsById[id];
  const [updateContact, updatingContact] = useUpdateContactQuery(() => {
    toast.success("Saved contact information!")
  })

  const onSubmit = (values) => {
    updateContact({
      userId: id, 
      email: values.email,
      first_name: values.firstName,
      last_name: values.lastName,
      work_phone: values.phoneNumber,
      cell_phone: values.phoneNumber,
      agency: values.agency,
      comments: '',
      contact_id: contact.contact_id,
      tdis_contact_id: contact.contact_id,
    })
  }

  if (!agencyQueryFinished) {
    return <Tile>
      <WaveLoader />
    </Tile>
  }
  
  return (
    <Formik
      validate={validatePersonalContact}
      onSubmit={onSubmit}
      enableReinitialize={true}
      initialValues={{
        ...(contact || {
          firstName: '',
          lastName: '',
          email: '',
          phoneNumber: '',
        })
      }}>
      {({ handleSubmit, errors, touched, submitCount }) => {
        const getError = (name) => (touched[name] || submitCount >= 1) && errors[name];
        return (
          <Tile>
            <div style={{ display: 'flex', justifyContent: 'space-between', paddingTop: '10px', alignItems: 'flex-start' }}>
              <h3 style={{marginTop: '0px'}}>Update Contact Information</h3>
            </div>
            <MultiFieldLine columns="50% 50%">
              <TextField name={`firstName`} label="First Name*" error={getError(`firstName`)} />
              <TextField name={`lastName`} label="Last Name*" error={getError(`lastName`)} />
            </MultiFieldLine>
            <MultiFieldLine columns="50% 50%">
              {/* <TextField isDisabled={true} name={`email`} label="Email" error={getError(`email`)} /> */}
              <TextField name={`phoneNumber`} label="Phone number*" error={getError(`phoneNumber`)} />
              <SelectField options={agencyOptions} optionSelector={getAgencyOptionFromValue} name={`agency`} label="Affiliation*" error={getError(`agency`)} />
            </MultiFieldLine>
            <Button type='btn' onClick={handleSubmit} isLoading={updatingContact} wrapperStyle={{marginTop: '20px'}}>
              Update
            </Button>
          </Tile>
      )}}
    </Formik>
  )
}