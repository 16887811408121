import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useRequest } from 'redux-query-react';
import Select from 'react-select';
import { contactRolesByRecordIdSelector, contactsByRecordIdSelector, originatorContactsByRecordIdSelector } from '../selectors/general';
import { getContactByIdQuery } from '../actions/queries';
import { contactsByIdSelector, originatorRoleIdSelector } from '../selectors/entities';
import { useDispatch, useSelector } from 'react-redux';
import { FOSSIL, GREY_DARK, GREY_MEDIUM, GREY_MEDIUM_DARK, GREY_LIGHTER } from '../constants/cssVars';
import { LinkLikeButton } from './Button';
import { FieldWrapper } from './FormFields';
import { setContactModal } from '../actions/modals';
import { addEmptyContactToRecord, setContactsAndRoles, updateContactRole, updateOriginatorContactId } from '../actions/general';
import { useAgencyOptions, useContactRoleOptions } from '../constants/formOptions';
import { WaveLoader } from './Loaders';
import { useCurrentUser } from '../hooks/useCurrentUser';
import { stringIsEmptyOrNull } from '../utils/helpers';

const ContactBarWrapper = styled.div`
  background-color: #fff;
  margin: 5px 0px;
  padding: 0px 2px;
  border-radius: 4px;
  border: 1px solid ${GREY_MEDIUM};
  display: flex;
  justify-content: flex-start;
  > div:first-child {
    font-weight: 600;
    color: ${GREY_DARK};
    max-width: 25%;
  }
  > div {
    border-right: 1px solid ${GREY_MEDIUM};
    margin: 7px 0px;
    padding: 1px 10px;
    color: ${GREY_MEDIUM_DARK};
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 20%;
  }
  > div:last-child {
    border: none;
  }
`

const ClickableContactTileWrapper = styled.div`
  background-color: #fff;
  margin: 10px 0px;
  padding: 10px 15px;
  border-radius: 4px;
  border: 1px solid ${GREY_MEDIUM};
  display: flex;
  flex-direction: column;
  cursor: pointer;
  justify-content: flex-start;
  > div:first-child {
    font-weight: 600;
    color: ${GREY_DARK};
  }
  > div {
    margin: 4px 0px;
    color: ${GREY_MEDIUM_DARK};
  }
  > div:last-child {
    border: none;
  }
  &:hover {
    background-color: ${GREY_LIGHTER};
  }
`

const SelectOrCreateWrapper = styled.div`
  background-color: #E5E9EB;
  border-radius: 0px;
  color: ${GREY_DARK};
  padding: 10px;
  text-align: center;
  border: 1px dashed ${FOSSIL};
`

// Display element that summarizes a contact in a compact bar
export const ContactBar = ({ contact, contactId, recordId, isOriginator, index }) => {
  const [, getAgencyOptionFromValue] = useAgencyOptions();
  const dispatch = useDispatch();
  const setContactModalProps = { showModal: true, contactId, isOriginator, recordId, index, selectExisting: false }
  // due to some discrepancies in agency being passed as a numeric ID or name, we want to check if it's an id & display the name otherwise
  const agency = isNaN(contact.agency) ? contact.agency : (getAgencyOptionFromValue(contact.agency) || {}).label

  return <ContactBarWrapper>
    <div>{contact.firstName} {contact.lastName}</div>
    <div>{contact.email}</div>
    <div>{contact.phoneNumber}</div>
    <div style={{ border: 'none' }}>{agency}</div>
    <LinkLikeButton style={{ marginLeft: 'auto', paddingRight: '10px' }} onClick={() => dispatch(setContactModal(setContactModalProps))}>Edit</LinkLikeButton>
  </ContactBarWrapper>
}

// Tile used in the "select an existing contact" modal that can be clicked on to add the contact
export const ClickableContactTile = ({ contact, onClick }) => {
  return <ClickableContactTileWrapper onClick={onClick}>
    <div>{contact.firstName} {contact.lastName}</div>
    <div>{contact.email}</div>
    <div>{contact.phoneNumber}</div>
    <div>{contact.agency}</div>
  </ClickableContactTileWrapper>
}

// Area that presents two buttons, select an existing contact or create a new one
const SelectOrCreateContactField = ({ recordId, index, isOriginator }) => {
  const dispatch = useDispatch();
  const setContactModalProps = { showModal: true, isOriginator, recordId, index, selectExisting: false }

  return <SelectOrCreateWrapper>
    <LinkLikeButton onClick={() => dispatch(setContactModal({ ...setContactModalProps, selectExisting: true }))}>
      Select an existing contact
    </LinkLikeButton>
    {` or `}
    <LinkLikeButton onClick={() => dispatch(setContactModal(setContactModalProps))}>
      Create a new contact
    </LinkLikeButton>
  </SelectOrCreateWrapper>
}

// Add a new contact button
export const AddAContactField = ({ onClick }) => {
  return <SelectOrCreateWrapper>
    <LinkLikeButton onClick={onClick}>
      + Add a contact
    </LinkLikeButton>
  </SelectOrCreateWrapper>
}

// Depending on whether or not a contact exists, show the contact in a summary bar or show a field to select or create a contact
export const ContactFormField = (props) => {
  const { contactId } = props;
  const { accessToken } = useCurrentUser();
  useRequest(contactId ? getContactByIdQuery(contactId, accessToken) : null);
  
  const contactsById = useSelector(contactsByIdSelector);
  const contact = contactsById[contactId];

  return (
    <FieldWrapper {...props}>
      {contact ? <ContactBar contact={contact} {...props} /> : <SelectOrCreateContactField {...props} />}
    </FieldWrapper>
  )
}

// Unstyled display of contact information, used for the UploadedView
export const SimpleContactDisplay = ({ contactId, contactRoleId }) => {
  const { accessToken } = useCurrentUser();
  useRequest(contactId ? getContactByIdQuery(contactId, accessToken) : null);
  const contactsById = useSelector(contactsByIdSelector);
  const contact = contactsById[contactId];
  const [,,, getRoleNameFromId] = useContactRoleOptions();

  if (!contact) {
    return `Contact id: ${contactId}, role id: ${contactRoleId}`
  }

  return <div>
    {contact.firstName} {contact.lastName}, {contact.email}, {contact.phoneNumber},{` `}
    {contact.agency} (Role: {getRoleNameFromId(contactRoleId)})
  </div>
}


// Return error-grabbing function for contacts
export const useContactFieldErrors = (recordId, values) => {
  const contactsByRecordId = useSelector(contactsByRecordIdSelector);
  const contactRolesByRecordId = useSelector(contactRolesByRecordIdSelector);
  const originatorContactsByRecordId = useSelector(originatorContactsByRecordIdSelector);
  const contacts = contactsByRecordId[recordId] || [];
  const contactRoles = contactRolesByRecordId[recordId] || [];
  const originatorContactId = originatorContactsByRecordId[recordId];

  const errors = {};
  contacts.forEach((contact, idx) => {
    if (stringIsEmptyOrNull(contact)) {
      errors[`contact_id_${idx}`] = 'Must specify a contact'
    }
  })

  contactRoles.forEach((role, idx) => {
    if (stringIsEmptyOrNull(role)) {
      errors[`contact_role_${idx}`] = 'Must specify a role'
    }
  })

  if (stringIsEmptyOrNull(originatorContactId)) {
    // This can be null I guess, TODO: better experience would be do return an error if the "add contact for originator"
    // box is checked
  }

  return errors;
}

// Align contact redux state with current record values
export const ContactFieldAligner = ({ recordId, previousMetadata }) => {
  const { id } = useCurrentUser();
  const contactsById = useSelector(contactsByIdSelector);
  const currentUserContact = contactsById[id];

  const dispatch = useDispatch();
  const originatorRoleId = useSelector(originatorRoleIdSelector);

  // Handle initial state (map DB returned contacts to our contact structure ya-hear?)
  const previousContactState = previousMetadata ? previousMetadata.contacts : null;
  useEffect(() => {
    if (Array.isArray(previousContactState)) {
      let contacts = previousContactState.map((arr) => arr[0]);
      let roles = previousContactState.map((arr) => arr[1]);
      const originatorRoleIdx = roles.findIndex(role => role === originatorRoleId);
      if (originatorRoleIdx > -1) {
        dispatch(updateOriginatorContactId(contacts[originatorRoleIdx], recordId))
        contacts.splice(originatorRoleIdx, 1)
        roles.splice(originatorRoleIdx, 1)
      }
      dispatch(setContactsAndRoles(recordId, contacts, roles));
    }
  }, [previousContactState])

  // If it's a new record, initialize some contacts.
  useEffect(() => {
    if (recordId === 'new') {
      // If the current user has an associated contact, add it to the record as a "metadata creator" instead of an empty contact
      if (currentUserContact) {
        // "1" is the metadata creator role ID
        dispatch(setContactsAndRoles(recordId, [currentUserContact.contact_id], [1]));
      } else {
        dispatch(addEmptyContactToRecord(recordId))
      }
    }
  }, [])

  return null;
}

// Converts current contact state to DB field value. e.g. "{33,7},{84,8}"
export const useConvertedContactFields = (recordId) => {
  const contactsByRecordId = useSelector(contactsByRecordIdSelector);
  const contactRolesByRecordId = useSelector(contactRolesByRecordIdSelector);
  const originatorContactsByRecordId = useSelector(originatorContactsByRecordIdSelector);
  const contacts = contactsByRecordId[recordId] || [];
  const contactRoles = contactRolesByRecordId[recordId];
  const originatorContactId = originatorContactsByRecordId[recordId];
  const originatorRoleId = useSelector(originatorRoleIdSelector);

  let allContactPairs = contacts.map((contact, idx) => `{${contact},${contactRoles[idx]}}`);
  if (!stringIsEmptyOrNull(originatorContactId)) {
    allContactPairs = [...allContactPairs, `{${originatorContactId},${originatorRoleId}}`]
  }

  return allContactPairs.join(',');
}

// If the formik field "showOriginatorContact" is set to false, remove the originator contact from redux state
export const ShowOriginatorTracker = ({ values, recordId }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (!values.showOriginatorContact) {
      dispatch(updateOriginatorContactId(null, recordId))
    }
  }, [values.showOriginatorContact]);
  return null;
}

export const ContactRoleSelectField = (props) => {
  const { index, recordId } = props;
  const dispatch = useDispatch();
  const [roleOptions, getRoleOptionFromValue, rolesQueryFinished] = useContactRoleOptions();
  const contactRolesByRecordId = useSelector(contactRolesByRecordIdSelector);

  if (!rolesQueryFinished) {
    return <WaveLoader />
  }

  const contactRoles = contactRolesByRecordId[recordId];
  const currentValue = contactRoles[index];
  const optionSelector = getRoleOptionFromValue;

  const handleChange = (newValue) => {
    dispatch(updateContactRole(newValue.value, recordId, index))
  };

  return (
    <FieldWrapper {...props}>
      <Select
        defaultValue={optionSelector(currentValue)}
        className="custom-react-select"
        classNamePrefix="select"
        value={optionSelector(currentValue)}
        onChange={handleChange}
        options={roleOptions}
        />
    </FieldWrapper>
  )
}
