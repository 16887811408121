import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { mutateAsync } from 'redux-query'
import { loginQuery } from '../../actions/queries'
import { statusIsGood } from '../../utils/helpers'
import { useCurrentUser } from '../useCurrentUser'
import { useSelectedRecord } from '../useSelectedRecord'

const TDIS_DATA_CATALOG_URL_BASE =
  process.env.REACT_APP_TDIS_DATA_CATALOG_URL_BASE ||
  'https://tdisproddatalakehouse.blob.core.windows.net/tdis-data-bronze'

const SAS_TOKEN = process.env.REACT_APP_UPLOAD_SAS_TOKEN || 'test'

const getPowershellScript = (bucket) => {
  // Ensure TDIS_DATA_CATALOG_URL_BASE and SAS_TOKEN are passed as parameters or defined elsewhere in your script
  return `###
# PowerShell script to upload files to Azure Storage using AzCopy and logging the upload status.
# Requirements: AzCopy v10, PowerShell 5.1 or higher.

# Define the incoming directory as a parameter
\$incomingDir = \$args[0]
\$destinationPath = Join-Path -Path \$env:USERPROFILE -ChildPath "azcopy"

# Check if AzCopy is already installed
if (Test-Path (Join-Path \$destinationPath "azcopy.exe")) {
    Write-Host "AzCopy is already installed at: \$destinationPath" -ForegroundColor Yellow
} else {
    # Download the latest AzCopy release
    \$ProgressPreference = 'SilentlyContinue'
    \$initialUri = 'https://aka.ms/downloadazcopy-v10-windows'
    \$response = Invoke-WebRequest -Uri \$initialUri -UseBasicParsing -ErrorAction SilentlyContinue

    if (\$response.StatusCode -eq 301) {
        \$redirectUri = \$response.Headers.Location
        \$response = Invoke-WebRequest -Uri \$redirectUri -UseBasicParsing
    }
    if (\$response.StatusCode -eq 200) {
        \$downloadPath = Join-Path -Path \$env:TEMP -ChildPath "azcopy.zip"
        [System.IO.File]::WriteAllBytes(\$downloadPath, \$response.Content)
    } else {
        Write-Host "Failed to retrieve AzCopy download URL." -ForegroundColor Red
        return
    }
    # Extract the AzCopy files
    Write-Host "Extracting AzCopy files..." -ForegroundColor Green
    Add-Type -AssemblyName System.IO.Compression.FileSystem
    [System.IO.Compression.ZipFile]::ExtractToDirectory(\$downloadPath, \$destinationPath)
    # Clean up the downloaded ZIP file
    Remove-Item \$downloadPath
    # Update the destinationPath to include the specific AzCopy version if necessary
    \$destinationPath = Join-Path -Path \$env:USERPROFILE -ChildPath "azcopy\\azcopy_windows_amd64_10.23.0" # Modify 10.x.x to the actual version if needed
    Write-Host "To run AzCopy, use the following command: & '\$destinationPath\\azcopy.exe'" -ForegroundColor Green
}

# Specify the SAS token and the destination URI
\$azCopyPath = Join-Path -Path \$destinationPath -ChildPath "azcopy.exe"
\$destinationUri = "${TDIS_DATA_CATALOG_URL_BASE}/${bucket}/${SAS_TOKEN}"

# Upload file using AzCopy
if (Test-Path \$azCopyPath) {
    if (Test-Path \$incomingDir) {
        Start-Process -FilePath \$azCopyPath -ArgumentList "copy \`"\$incomingDir\`" \`"\$destinationUri\`"" -Wait -NoNewWindow
    } else {
        Write-Host "The specified directory '\$incomingDir' does not exist." -ForegroundColor Red
    }
} else {
    Write-Host "AzCopy executable not found at: \$azCopyPath" -ForegroundColor Red
}
###`
}

export const usePowershellDownload = () => {
  const dispatch = useDispatch()
  const [selectedRecord] = useSelectedRecord()
  const { username } = useCurrentUser()
  const regionId =
    (selectedRecord
      ? selectedRecord.currentMetadata.twdbFloodPlanningRegionID
      : null) || '01'

  // Generate a UUID v4
  const uuid = ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  )

  // Concatenate the generated UUID with the bucket name
  const bucket = `twdb-region-${regionId}/${uuid}`

  const promptForPassword = () => {
    const passwordInput = prompt('Please re-enter your password', '')
    dispatch(
      mutateAsync(loginQuery({ username, password: passwordInput }))
    ).then(({ status }) => {
      if (statusIsGood(status)) {
        getFile(passwordInput)
      } else {
        toast.error('Incorrect password, please try again')
      }
    })
  }

  const getFile = (currentPassword) => {
    const exportedFilename = `upload_script.ps1`
    var blob = new Blob([getPowershellScript(bucket)], {
      type: 'text/plain;charset=utf-8;',
    })
    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(blob, exportedFilename)
    } else {
      var link = document.createElement('a')
      if (link.download !== undefined) {
        var url = URL.createObjectURL(blob)
        link.setAttribute('href', url)
        link.setAttribute('download', exportedFilename)
        link.style.visibility = 'hidden'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
    }
  }

  return promptForPassword
}
