import { useSelector } from 'react-redux'
import { recordsSelector } from '../selectors/entities'
import { searchStrSelector } from '../selectors/general'
import { stringIsEmptyOrNull } from '../utils/helpers'

// filter by model name
export const useFilteredRecords = () => {
  const records = useSelector(recordsSelector)
  const searchStr = useSelector(searchStrSelector)

  // Reduce records to unique by 'tdisIdentifier'
  const uniqueRecords = records.reduce((acc, current) => {
    const x = acc.find((item) => item.tdisIdentifier === current.tdisIdentifier)
    if (!x) {
      return acc.concat([current])
    } else {
      return acc
    }
  }, [])

  // If the search string is empty or null, return unique records
  if (!searchStr) {
    return uniqueRecords
  }

  // Return filtered unique records based on the search string
  return uniqueRecords.filter(
    (record) =>
      record.currentMetadata.modelName &&
      record.currentMetadata.modelName
        .toLowerCase()
        .includes(searchStr.toLowerCase())
  )
}
