// Initial values for generating new metadata
export const metadataInit = {
  rbfsRegionID: '',
  hydrologicUnitCode: '',
  county: '',
  city: '',
  modelName: '',
  creator: '',
  modelDescription: '',
  modelPurpose: '',
  kewyWordTerm: '',
  updateFrequency: '',
  useConstraint: '',
  modelType: '',
  modelConfigFile: '',
  modelInput: '',
  modelOutput: '',
  softwareName: '',
  softwareVersion: '',
  contactName: '',
  contactAffiliation: '',
  contactEmail: '',
  contactPhoneNumber: '',
  contactDepartment: '',
  spatialDataType: '',
  spatialExtentDecimalDegrees: '',
  boundaryName: '',
  horizantalCoordinateSystem: '',
}

// User-friendly names & descriptions for each metadata record field,
// & whether or not the field is required & accepts multiple values
export const metadataFieldInfo = {
  rbfsRegionID: {
    friendlyName: 'Region',
    description: 'The region uploading the data.',
    required: true,
    acceptsMultipleValues: false,
  },
  hydrologicUnitCode: {
    friendlyName: 'HUC',
    description:
      'The hydrologic unit code associated with the primary watershed for the model.',
    required: false,
    acceptsMultipleValues: false,
  },
  county: {
    friendlyName: 'County',
    description: 'The county that the model spans',
    required: true,
    acceptsMultipleValues: false,
  },
  city: {
    friendlyName: 'City',
    description: 'The city that the model spans.',
    required: false,
    acceptsMultipleValues: false,
  },
  modelName: {
    friendlyName: 'Model Name',
    description: 'The name or title of the model/dataset.',
    required: true,
    acceptsMultipleValues: false,
  },
  creator: {
    friendlyName: 'Model Creator',
    description:
      'The name of the organization contributing to the initial creation.',
    required: true,
    acceptsMultipleValues: false,
  },
  modelPurpose: {
    friendlyName: 'Model Purpose',
    description:
      'The purpose of the model/dataset. Include information on scenarios or simulations here.',
    required: true,
    acceptsMultipleValues: false,
  },
  modelDescription: {
    friendlyName: 'Model Description',
    description: 'The description of the model/dataset.',
    required: true,
    acceptsMultipleValues: false,
  },
  kewyWordTerm: {
    friendlyName: 'Keyword term(s)',
    description: 'comma separated subject matter keywords.',
    required: true,
    acceptsMultipleValues: false,
  },
  updateFrequency: {
    friendlyName: 'Update Frequency',
    description: 'How often is this content updated.',
    required: true,
    acceptsMultipleValues: false,
  },
  useConstraint: {
    friendlyName: 'Use Constraint',
    description: 'Constraints or restrictions on use of the digital object.',
    required: true,
    acceptsMultipleValues: false,
  },
  modelType: {
    friendlyName: 'Model Type',
    description:
      'The type of model ( hydrologic/ hydraulic 1d, hydraulic 1d/2d, hydraulic 2d, Other).',
    required: true,
    acceptsMultipleValues: false,
  },
  modelConfigFile: {
    friendlyName: 'Model Config File',
    description:
      'The name of file(s) required as parameters for running the model.',
    required: false,
    acceptsMultipleValues: false,
  },
  modelInput: {
    friendlyName: 'Model Input',
    description: 'The name(s) of an Artifact or Layer serving as input.',
    required: false,
    acceptsMultipleValues: false,
  },
  modelOutput: {
    friendlyName: 'Model Output',
    description: 'The name(s) of an Artifact or Layer serving as output.',
    required: false,
    acceptsMultipleValues: false,
  },

  softwareName: {
    friendlyName: 'Model Software Name',
    description: 'The name of the model software used.',
    required: true,
    acceptsMultipleValues: false,
  },
  softwareVersion: {
    friendlyName: 'Model Software Version',
    description: 'The version of the software used.',
    required: true,
    acceptsMultipleValues: false,
  },
  contactName: {
    friendlyName: 'Contact Name',
    description: 'Name of a person who is a responsible point of contact.',
    required: true,
    acceptsMultipleValues: false,
  },
  contactAffiliation: {
    friendlyName: 'Contact Affiliation',
    description:
      'Organizational affiliation for the responsible point of contact.',
    required: true,
    acceptsMultipleValues: false,
  },
  contactEmail: {
    friendlyName: 'Contact Email',
    description: 'Email for the responsible point of contact.',
    required: true,
    acceptsMultipleValues: false,
  },

  contactPhoneNumber: {
    friendlyName: 'Contact Phone Number',
    description: 'Work phone number for the responsible point of contact.',
    required: true,
    acceptsMultipleValues: false,
  },
  contactDepartment: {
    friendlyName: 'Contact Department',
    description:
      'The role(s) of the point of contact toward the digital object and its management within TDIS.',
    required: true,
    acceptsMultipleValues: false,
  },
  spatialDataType: {
    friendlyName: 'Spatial DataType',
    description: 'The type of spatial data',
    required: true,
    acceptsMultipleValues: false,
  },
  spatialExtentDecimalDegrees: {
    friendlyName: 'Spatial Extent Decimal Degrees',
    description:
      'Coordinates for the bounding box describing the spatial extent covered in decimal degrees. This is the spatial extent of the watershed or the area of interest for the modeling domain. This or boundary name is required.',
    required: false,
    acceptsMultipleValues: false,
  },
  boundaryName: {
    friendlyName: 'Boundary Name',
    description:
      'The geo spatial boundary associated. This could be a reference to a shape file for the watershed, or the area of interest for the modeling domain in a shape file format. User is required to either provide this or the Spatial Extent field.',
    required: false,
    acceptsMultipleValues: false,
  },
  horizantalCoordinateSystem: {
    friendlyName: 'Horizontal Coordinate System',
    description:
      'A projected coordinate system is a planar system that uses two-dimensional coordinates and linear distance measurements as units.',
    required: true,
    acceptsMultipleValues: false,
  },
}
