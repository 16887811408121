import React from 'react'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { useRequest } from 'redux-query-react'
import styled from 'styled-components'
import { getModelArchivesQuery } from '../actions/queries'
import { PRIMARY } from '../constants/cssVars'
import { useCurrentUser } from '../hooks/useCurrentUser'
import { useSelectedRecord } from '../hooks/useSelectedRecord'
import { archivesSelector } from '../selectors/entities'
import downloadIcon from '../assets/icons/download_white.svg'

const DownloadButton = styled.button`
  font-family: 'Open Sans Condensed', sans-serif;
  text-transform: uppercase;
  font-weight: 600;
  background-color: ${PRIMARY};
  color: #fff;
  font-size: 1em;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.11);
  padding: 10px 25px;
  text-align: center;
  cursor: pointer;
  border: none;
  text-decoration: none;
  cursor: pointer;

  display: inline-flex;
  align-items: center;
  > img {
    width: 20px;
    padding-left: 10px;
  }
`

const TDIS_DATA_CATALOG_URL_BASE =
  process.env.REACT_APP_TDIS_DATA_CATALOG_URL_BASE ||
  'https://tdisproddatalakehouse.blob.core.windows.net/tdis-data-bronze'

const SAS_TOKEN = process.env.REACT_APP_SAS_TOKEN || 'test'

// Uploaded files header with download zip button
export const UploadedFilesHeader = () => {
  const [, recordId] = useSelectedRecord()
  const { accessToken } = useCurrentUser()
  useRequest(accessToken ? getModelArchivesQuery(recordId, accessToken) : null)
  const archives = useSelector((state) => archivesSelector(state, recordId))
  const nonArchivedObjs = archives.filter(
    (archiveObj) => !archiveObj.properties.archived
  )

  const handleDownloadClick = (url) => () => {
    var link = document.createElement('a')
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      link.setAttribute('href', url)
      // link.setAttribute("download", exportedFilename);
      link.style.visibility = 'hidden'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    } else {
      window.open(url, '_blank').focus()
    }
  }

  // If there's more than one archived object, display as indexed list
  if (nonArchivedObjs.length > 1) {
    return (
      <div style={{ marginBottom: '10px' }}>
        <h3 style={{ marginTop: '0px' }}>Uploaded Files</h3>
        <p>Multiple uploads found</p>
        {nonArchivedObjs.map((obj, idx) => {
          return (
            <DownloadButton
              onClick={handleDownloadClick(
                `${TDIS_DATA_CATALOG_URL_BASE}/${obj.properties.path}${SAS_TOKEN}`
              )}
              style={{ marginRight: '10px', marginTop: '10px' }}
            >
              Download ZIP {idx + 1} (ADDED ON{' '}
              {moment(obj.properties.uploaded).format('MMMM Do YYYY, h:mm a')}{' '}
              by {obj.properties.username})
              <img src={downloadIcon} alt="" />
            </DownloadButton>
          )
        })}
      </div>
    )
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        paddingTop: '10px',
        alignItems: 'flex-start',
      }}
    >
      <h3 style={{ marginTop: '0px' }}>Uploaded Files</h3>
      {nonArchivedObjs.length > 0 ? (
        <DownloadButton
          onClick={handleDownloadClick(
            `${TDIS_DATA_CATALOG_URL_BASE}/${nonArchivedObjs[0].properties.path}${SAS_TOKEN}`
          )}
        >
          Download ZIP
          <img src={downloadIcon} alt="" />
        </DownloadButton>
      ) : null}
    </div>
  )
}
