import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Button, LinkLikeButton } from './Button'
import {
  BG_LIGHTER,
  SECONDARY,
  SHADOW_MEDIUM,
  PRIMARY,
  SLATE,
  ICE,
  BG_PRIMARY,
  BG_DARKER,
} from '../constants/cssVars.js'
import { useHistory, useParams } from 'react-router-dom'
import { Formik } from 'formik'
import { TextField } from './FormFields'
import { validateForgotPassword, validateLogin } from '../utils/validators'
import { useLoginQuery } from '../hooks/messagesAndRequests'
import { LoggedOutHeader } from './LoggedOutHeader'

const Wrapper = styled.div`
  width: 100%;
`

const MainContent = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: radial-gradient(
    circle at top right,
    ${BG_LIGHTER},
    ${BG_DARKER}
  );
`

export const Tile = styled.div`
  padding: 30px 20px;
  background-color: ${BG_LIGHTER};
  box-shadow: ${SHADOW_MEDIUM};
  border: 1px solid ${PRIMARY};
  border-radius: 0px;
  display: flex;
  flex-direction: column;
  width: 350px;
`

const IntroText = styled.h1`
  color: ${PRIMARY};
  font-weight: 600;
  padding-bottom: 20px;
  margin: 0px;
  font-size: 30px;
  text-align: center;
`

const ButtonWrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 15px;
  text-align: center;
`

const EnterKeyPressListener = ({ values, handleSubmit }) => {
  const onKeyDown = (e) => {
    if (e.key === 'Enter' || e.keyCode === 13) {
      e.preventDefault()
      handleSubmit(values)
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', onKeyDown)
    return () => {
      window.removeEventListener('keydown', onKeyDown)
    }
  }, [])

  return null
}

const LoginContent = ({ setShowForgotPassword }) => {
  const history = useHistory()

  const [submit, submitting] = useLoginQuery(() => {
    history.push('/app/')
  })

  return (
    <Formik
      validate={validateLogin}
      initialValues={{
        username: '',
        password: '',
      }}
      onSubmit={submit}
    >
      {({ handleSubmit, errors, touched, values, submitCount }) => {
        const getError = (name) =>
          (touched[name] || submitCount >= 1) && errors[name]

        return (
          <>
            <IntroText>Login</IntroText>
            <TextField
              label="Username"
              error={getError('username')}
              style={{ margin: '5px 0px ' }}
              name="username"
            />
            <TextField
              label="Password"
              error={getError('password')}
              style={{ margin: '5px 0px ' }}
              type="password"
              name="password"
            />
            <ButtonWrapper>
              <Button
                onClick={handleSubmit}
                type="button"
                isLoading={submitting}
              >
                Log in
              </Button>
            </ButtonWrapper>
            <EnterKeyPressListener
              handleSubmit={handleSubmit}
              values={values}
            />
            {/* <LinkLikeButton style={{fontSize: '16px'}} onClick={() => {
              trackEvent(BUTTON_CLICK, "Forgot password")
              setShowForgotPassword(true)}
            }>
              Forgot password
            </LinkLikeButton> */}
          </>
        )
      }}
    </Formik>
  )
}

const ForgotPasswordContent = ({ setShowForgotPassword }) => {
  const [resetSubmit, resetSubmitting] = [() => console.log('OK'), false]

  return (
    <Formik
      validate={validateForgotPassword}
      initialValues={{ email: '' }}
      onSubmit={resetSubmit}
    >
      {({ handleSubmit, errors, touched, values, submitCount }) => {
        const getError = (name) =>
          (touched[name] || submitCount >= 1) && errors[name]
        return (
          <>
            <IntroText>Forgot Password</IntroText>
            <p
              style={{
                margin: '0px 0px 10px 0px',
                padding: '0px',
                fontSize: '17px',
              }}
            >
              Please enter your account email below. We'll send you an email
              with instructions on how to reset your password.
            </p>
            <TextField
              label="Email"
              error={getError('email')}
              style={{ margin: '5px 0px ' }}
              type="email"
              name="email"
            />
            <ButtonWrapper>
              <Button onClick={handleSubmit} isLoading={resetSubmitting}>
                Send Reset Instructions
              </Button>
            </ButtonWrapper>
            <LinkLikeButton
              style={{ fontSize: '16px' }}
              onClick={() => setShowForgotPassword(false)}
            >
              Back to login
            </LinkLikeButton>
          </>
        )
      }}
    </Formik>
  )
}

export const Login = () => {
  const [showForgotPassword, setShowForgotPassword] = useState(false)

  return (
    <Wrapper>
      <LoggedOutHeader />
      <MainContent>
        <Tile borderColor={SECONDARY}>
          {showForgotPassword ? (
            <ForgotPasswordContent
              setShowForgotPassword={setShowForgotPassword}
            />
          ) : (
            <LoginContent setShowForgotPassword={setShowForgotPassword} />
          )}
        </Tile>
      </MainContent>
    </Wrapper>
  )
}
