import { useSelector } from "react-redux";
import { USER_STORAGE_KEY } from "../constants/general";
import { queriedUserSelector } from "../selectors/entities";

export const useCurrentUser = () => {
  // First, use the queried user 
  const queriedUser = useSelector(queriedUserSelector);

  if (queriedUser != null) {
    return queriedUser;
  }
  const localStorageUser = JSON.parse(localStorage.getItem(USER_STORAGE_KEY));
  if (localStorageUser && localStorageUser.id) {
    return localStorageUser;
  }

  return {};
}