import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { toast } from 'react-toastify'
import { mutateAsync } from 'redux-query'
import {
  duplicateRecordQuery,
  saveRecordQuery,
  loginQuery,
  resetPasswordQuery,
  sendMessageQuery,
  deleteRecordQuery,
  addContactQuery,
  updateContactQuery,
  resolveFileUploadConflictQuery,
  batchResolveUploadConflictQuery,
  getModelArchivesQuery,
  getRecordQuery,
} from '../actions/queries'
import { USER_STORAGE_KEY } from '../constants/general'
import {
  compareObjectProperties,
  prettyPrintObject,
  statusIsGood,
  statusIsUnauthorized,
} from '../utils/helpers'
import { useHandleUnauthorized } from './useHandleUnauthorized'
import { useCurrentUser } from '../hooks/useCurrentUser'
import { setPassword } from '../actions/general'
import { convertAppFieldsToDbProperties } from '../utils/fieldConversion'

const SUCCESS_MESSAGE = 'Success!'
const ERROR_MESSAGE = 'An error occurred. Please try again later.'

const bodyAndStatusAreGood = (status, body) => {
  // if (body && body.message && (body.message.toLowerCase().includes('error') || body.message.toLowerCase().includes('problem'))) {
  //   return false;
  // }
  return statusIsGood(status)
}

// Hook we can reuse for simple queries
const useSimpleQuery = (query, onSuccess, successMsg, errorMsg) => {
  const [submitting, setSubmitting] = useState(false)
  const handleUnauthorized = useHandleUnauthorized()
  const { accessToken } = useCurrentUser()
  const dispatch = useDispatch()

  const submit = (values) => {
    setSubmitting(true)
    dispatch(mutateAsync(query(values, accessToken))).then(
      ({ status, body }) => {
        if (bodyAndStatusAreGood(status, body)) {
          // if (statusIsGood(status) && body) {
          setSubmitting(false)
          successMsg && toast.success(successMsg)
          // onSuccess && onSuccess(body);
          onSuccess && onSuccess(body)
        } else if (statusIsUnauthorized(status)) {
          handleUnauthorized()
        } else {
          setSubmitting(false)
          toast.error(errorMsg || ERROR_MESSAGE)
        }
      }
    )
  }
  return [submit, submitting]
}

export const useDuplicateRecord = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [submitting, setSubmitting] = useState(false)
  const { accessToken, id, username } = useCurrentUser()
  const handleUnauthorized = useHandleUnauthorized()

  const submit = (recordId) => {
    dispatch(mutateAsync(duplicateRecordQuery(accessToken, recordId))).then(
      ({ status, body }) => {
        if (bodyAndStatusAreGood(status, body)) {
          setSubmitting(false)
          // TODO: UPDATE THIS TO GET RECORD QUERY (not get all records) WHEN IT's AVAILABLE!!
          const newId =
            body &&
            Array.isArray(body.features) &&
            body.features.length > 0 &&
            body.features[0].properties.tdisIdentifier
          if (newId) {
            setSubmitting(false)
            toast.success('Duplicated record!')
            history.push(`/app/form/${newId}`)
          } else {
            setSubmitting(false)
            toast.error(ERROR_MESSAGE)
          }
        } else if (statusIsUnauthorized(status)) {
          handleUnauthorized()
        } else {
          setSubmitting(false)
          toast.error(ERROR_MESSAGE)
        }
      }
    )
  }
  return [submit, submitting]
}

export const useSaveRecord = (tdisDataIdentifier, hideSuccessMessage) => {
  const dispatch = useDispatch()
  const [submitting, setSubmitting] = useState(false)
  const handleUnauthorized = useHandleUnauthorized()
  const { accessToken, id } = useCurrentUser()

  const submit = async (values, onSuccess) => {
    setSubmitting(true)
    const recordId = tdisDataIdentifier === 'new' ? null : tdisDataIdentifier
    let transformedValues = convertAppFieldsToDbProperties({ ...values })
    if (recordId) {
      transformedValues = {
        ...transformedValues,
        tdisIdentifier: recordId,
        userId: id,
      }
    }
    transformedValues = { ...transformedValues, userId: id }

    // compareObjectProperties(transformedValues, mockSaveCall)
    dispatch(
      mutateAsync(
        saveRecordQuery(transformedValues, values, recordId, accessToken)
      )
    ).then(({ status, body }) => {
      if (bodyAndStatusAreGood(status, body)) {
        setSubmitting(false)
        if (!hideSuccessMessage) {
          toast.success('Saved record!')
        }
        const newId = body && body != null && body.tdisIdentifier
        onSuccess && onSuccess(newId)
      } else if (statusIsUnauthorized(status)) {
        handleUnauthorized()
      } else {
        setSubmitting(false)
        toast.error(ERROR_MESSAGE)
      }
    })
  }
  return [submit, submitting]
}

const acceptableRoles = [
  'ROLE_EDITOR',
  'ROLE_ADMIN',
  'ROLE_ADMIN-APPLICATION',
  'ROLE_ADMIN-ADVANCED',
]
const getEditorRoleMatch = (userRoles) => {
  return userRoles.some((userRole) => acceptableRoles.includes(userRole))
}

export const useLoginQuery = (onSuccess) => {
  const [submitting, setSubmitting] = useState(false)
  const dispatch = useDispatch()

  const submit = async (values) => {
    setSubmitting(true)
    dispatch(mutateAsync(loginQuery(values))).then(({ status, body }) => {
      const { roles } = body || {}
      const hasEditorRole = Array.isArray(roles) && getEditorRoleMatch(roles)
      if (statusIsGood(status) && hasEditorRole) {
        setSubmitting(false)
        dispatch(setPassword(values.password))
        localStorage.setItem(
          USER_STORAGE_KEY,
          JSON.stringify({
            username: values.username,
            ...body,
          })
        )
        onSuccess()
      } else {
        setSubmitting(false)
        toast.error('Could not log in with the provided credentials')
      }
    })
  }
  return [submit, submitting]
}

export const useResetPassword = () =>
  useSimpleQuery(
    resetPasswordQuery,
    null,
    'Reset password successfully!',
    'Failed to reset password.'
  )
export const useSendMessageQuery = (onSuccess) =>
  useSimpleQuery(sendMessageQuery, onSuccess, null, 'Failed to send message.')
export const useDeleteRecordQuery = (onSuccess) =>
  useSimpleQuery(
    deleteRecordQuery,
    onSuccess,
    'Successfully deleted record',
    'Failed to delete record.'
  )
export const useAddContactQuery = (onSuccess) =>
  useSimpleQuery(addContactQuery, onSuccess, null, 'Failed to add contact.')
export const useUpdateContactQuery = (onSuccess) =>
  useSimpleQuery(
    updateContactQuery,
    onSuccess,
    null,
    'Failed to update contact.'
  )
export const useResolveFileConflictQuery = (onSuccess) =>
  useSimpleQuery(
    resolveFileUploadConflictQuery,
    onSuccess,
    'Resolved file conflict',
    'Failed to resolve file conflict.'
  )
export const useBatchResolveConflictQuery = (onSuccess) =>
  useSimpleQuery(
    batchResolveUploadConflictQuery,
    onSuccess,
    'Selected upload',
    'Failed to resolve conflict.'
  )
export const useGetRecordQuery = (onSuccess) =>
  useSimpleQuery(getRecordQuery, onSuccess, null, null)
