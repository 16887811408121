export const SET_ACTIVE_DROPDOWN = 'SET_ACTIVE_DROPDOWN';
export const SET_TABLE_VIEW = 'SET_TABLE_VIEW';
export const SET_SEARCH = 'SET_SEARCH';
export const SET_PASSWORD = 'SET_PASSWORD';
export const SET_SELECTED_METADATA_ID = 'SET_SELECTED_METADATA_ID';
export const ADD_EMPTY_CONTACT_TO_RECORD = 'ADD_EMPTY_CONTACT_TO_RECORD';
export const SET_CONTACTS_AND_ROLES = 'SET_CONTACTS_AND_ROLES';
export const REMOVE_CONTACT_FROM_RECORD = 'REMOVE_CONTACT_FROM_RECORD';
export const UPDATE_CONTACT_ID = 'UPDATE_CONTACT_ID';
export const UPDATE_CONTACT_ROLE = 'UPDATE_CONTACT_ROLE';
export const UPDATE_SELECTED_AGENCY = 'UPDATE_SELECTED_AGENCY';
export const ADD_ORIGINATOR_CONTACT_ID_TO_RECORD = 'ADD_ORIGINATOR_CONTACT_ID_TO_RECORD';
export const CLEAR_STORE = 'CLEAR_STORE';

export const setActiveDropdown = (dropdownId) => {
  return { type: SET_ACTIVE_DROPDOWN, payload: dropdownId };
}

export const setTableView = (showTableView) => {
  return { type: SET_TABLE_VIEW, payload: showTableView };
}

export const setSearch = (searchStr) => {
  return { type: SET_SEARCH, payload: searchStr };
}

export const setSelectedRecordId = (recordId) => {
  return { type: SET_SELECTED_METADATA_ID, payload: recordId };
}

export const setContactsAndRoles = (recordId, contacts, roles) => {
  return { type: SET_CONTACTS_AND_ROLES, payload: { recordId, contacts, roles } };
}

export const addEmptyContactToRecord = (recordId) => {
  return { type: ADD_EMPTY_CONTACT_TO_RECORD, payload: { recordId } };
}

export const removeContactFromRecord = (recordId, index) => {
  return { type: REMOVE_CONTACT_FROM_RECORD, payload: { recordId, index } };
}

export const updateContactId = (contactId, recordId, index) => {
  return { type: UPDATE_CONTACT_ID, payload: { contactId, recordId, index } };
}

export const updateContactRole = (contactRoleId, recordId, index) => {
  return { type: UPDATE_CONTACT_ROLE, payload: { contactRoleId, recordId, index } };
}

export const updateOriginatorContactId = (contactId, recordId) => {
  return { type: ADD_ORIGINATOR_CONTACT_ID_TO_RECORD, payload: { contactId, recordId } };
}

export const updateSelectedAgency = (agencyId) => {
  return { type: UPDATE_SELECTED_AGENCY, payload: { agencyId } };
}

export const setPassword = (pwrd) => {
  return { type: SET_PASSWORD, payload: { pwrd } };
}

// Tells the root reducer to completely clear the redux store, used for 401 encounters
export const clearStore = () => ({ type: CLEAR_STORE, payload: true })