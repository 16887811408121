import React, { useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { FOSSIL, ICE, SHADOW_CRISP } from '../constants/cssVars';
import { Button, LinkLikeButton } from './Button';
import { useSelector } from 'react-redux';
import { uploadConflictsByIdSelector } from '../selectors/entities';
import { getUploadConflictsQuery } from '../actions/queries';
import { useRequest } from 'redux-query-react';
import { useBatchResolveConflictQuery, useResolveFileConflictQuery } from '../hooks/messagesAndRequests';
import warningIcon from '../assets/icons/warning.svg'
import { useCurrentUser } from '../hooks/useCurrentUser';
import { QUERY_REFRESH_INTERVAL_MS } from '../constants/general';
import { useInterval } from '../hooks/useInterval';

const Tile = styled.div`
  margin-bottom: 20px;
  background-color: #fff;
  box-shadow: ${SHADOW_CRISP};
  border: 1px solid ${FOSSIL};
  text-align: left;
  padding: 20px;
`

const FileSelectionWrapper = styled.div`
  margin-top: 20px;
  padding: 10px;
  box-shadow: ${SHADOW_CRISP};
  background-color: ${ICE};
  > h3 {
    margin: 5px;
    font-size: 1.1em;
  }
`

const ConflictSelectionWrapper = styled.div`
  margin-top: 20px;
  font-size: 0.95em;
  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    padding: 10px;
    padding-left: 15px;
    margin: 5px 0px;
    box-shadow: ${SHADOW_CRISP};
  }
`

// When a contact is present, display selector for choosing which file or upload to move forward with
export const UploadConflictResolver = ({ recordId }) => {
  const [bulkResolveMode, setBulkResolveMode] = useState(true); 
  const { accessToken, username } = useCurrentUser();
  // Make this single query
  const [{ isFinished }, refresh] = useRequest(getUploadConflictsQuery(recordId, accessToken))
  const uploadConflictsById = useSelector(uploadConflictsByIdSelector);
  const uploadConflicts = uploadConflictsById[recordId];

  const [submitBulkResolve, submittingBulkResolve] = useBatchResolveConflictQuery(refresh);
  const [submitFileResolve, submittingFileResolve] = useResolveFileConflictQuery(refresh);


  // Occassionally refresh our conflicts
  useInterval(() => {
    refresh();
  }, QUERY_REFRESH_INTERVAL_MS);

  if (!Array.isArray(uploadConflicts) || uploadConflicts.length < 1) {
    return null;
  }

  // In bulk-resolve mode, allow user to select a full upload to use
  if (bulkResolveMode) {
    const conflictsByBatchId = (uploadConflicts || []).reduce((accum, conflictObj) => {
      if (!accum[conflictObj.upload_batch_id]) {
        accum[conflictObj.upload_batch_id] = [];
      }
      accum[conflictObj.upload_batch_id].push(conflictObj)
      return accum;
    }, {})
  
    return <Tile style={{ backgroundColor: '#E9D77F'}}>
      <div style={{ display: 'flex', justifyContent: 'flex-start', paddingTop: '10px', alignItems: 'center' }}>
        <img src={warningIcon} style={{ width: '25px', height: '25px', marginRight: '10px' }} alt="warning icon" />
        <h3 style={{margin: '0px', color: '#453E1D' }}>Multiple Uploads Detected</h3>
      </div>
      <p style={{margin: '10px 0px', color: '#453E1D', fontSize: '0.95em'}}>
        Multiple conflicting uploads were detected for this model. Please choose which upload should be used to represent this model, or {` `}
        <LinkLikeButton onClick={() => setBulkResolveMode(false)} bodyFont={1} style={{ color: '#453E1D', fontWeight: 500 }}>
          resolve file conflicts individually
        </LinkLikeButton>.
      </p>
      <h3 style={{margin: '0px', color: '#453E1D' }}>Bulk Resolution</h3>
      <ConflictSelectionWrapper>
        {Object.keys(conflictsByBatchId).map((batchId, idx) => {
          const firstConflict = conflictsByBatchId[batchId][0];
          return <div key={`upload_row_${idx}`}>
            Upload on {moment(firstConflict.uploaded).format('MMMM Do YYYY, h:mm a')} by {firstConflict.username}
            <Button short={1} isLoading={submittingBulkResolve} onClick={() => submitBulkResolve({
              username,
              model_tdis_identifier: recordId,
              upload_batch_id: batchId
             })}>
              Use this upload
            </Button>
          </div>
        })}
      </ConflictSelectionWrapper>
    </Tile>
  }

  // Otherwise, display individual files to resolve
  const conflictsByConflictNum = (uploadConflicts || []).reduce((accum, conflictObj) => {
    if (!accum[conflictObj.conflict_number]) {
      accum[conflictObj.conflict_number] = [];
    }
    accum[conflictObj.conflict_number].push(conflictObj)
    return accum;
  }, {})


  return <Tile style={{ backgroundColor: '#E9D77F'}}>
    <div style={{ display: 'flex', justifyContent: 'flex-start', paddingTop: '10px', alignItems: 'center' }}>
      <img src={warningIcon} style={{ width: '25px', height: '25px', marginRight: '10px' }} alt="warning icon" />
      <h3 style={{margin: '0px', color: '#453E1D' }}>Multiple Uploads Detected</h3>
    </div>
    <p style={{margin: '10px 0px', color: '#453E1D', fontSize: '0.95em'}}>
      Please resolve each file conflict present from multiple uploads. Or, {` `}
      <LinkLikeButton onClick={() => setBulkResolveMode(true)} bodyFont={1} style={{ color: '#453E1D', fontWeight: 500 }}>
        choose an entire upload
      </LinkLikeButton>.
    </p>
    <h3 style={{margin: '0px', color: '#453E1D' }}>Individual File Resolution</h3>
    {Object.keys(conflictsByConflictNum).map((conflictNum, idx) => {
      const conflictObjs = conflictsByConflictNum[conflictNum];
      return <FileSelectionWrapper key={`conflict_by_file_${idx}`}>
        <h3>{conflictObjs[0].filename}</h3>
        <ConflictSelectionWrapper style={{ marginTop: '10px' }}>
          {conflictObjs.map((conflictObj, conflictIdx) => {
            return <div key={`upload_row_${idx}_${conflictIdx}`}>
              '{conflictObjs[0].filename}' uploaded on {moment(conflictObj.uploaded).format('MMMM Do YYYY, h:mm a')} by {conflictObj.username}
              <Button width={180} onClick={() => submitFileResolve({ username, upload_id: conflictObj.upload_id, model_tdis_identifier: recordId })}>
                Use this version
              </Button>
            </div>
          })}
        </ConflictSelectionWrapper>
      </FileSelectionWrapper>
    })}
  </Tile>
}
