import React from 'react';
import styled from 'styled-components';
import { GREY_MEDIUM, PRIMARY, SHADOW_CRISP } from '../../constants/cssVars';

const TooltipWrapper = styled.div`
  position: relative;
  display: inline-block;
  &:hover div {
    visibility: visible;
  }
`

const TooltipContent = styled.div`
  visibility: hidden;
  width: 220px;
  background-color: #fff;
  font-size: 0.8em;
  border: 1px solid ${GREY_MEDIUM};
  box-shadow: ${SHADOW_CRISP};
  color: ${PRIMARY};
  text-align: center;
  border-radius: 6px;
  padding: 5px 5px;
  position: absolute;
  z-index: 1;
  bottom: 130%;
  left: 50%;
  margin-left: -113px;
  :after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -4px;
    border-width: 4px;
    border-style: solid;
    border-color: #fff transparent transparent transparent;
  }
`

export const Tooltip = ({ content, style, children }) => {
  return <TooltipWrapper>
    {children}
    <TooltipContent style={style}>
      {content}
    </TooltipContent>
  </TooltipWrapper>
}