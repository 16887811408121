import moment from "moment";

export const statusIsGood = (status) => status >= 200 && status < 300;
export const statusIsUnauthorized = (status) => status === 401;

export const stringIsEmptyOrNull = (str) => str == null || str === ''

export const getSlugFromName = (str) => str ? str.toLowerCase().split(' ').join('_') : null;

export const getLocalStorageItem = (key) => {
  try {
    const result = localStorage.getItem(key);
    return result;
  } catch {
    return null;
  }
}

export const setLocalStorageItem = (key, value) => {
  try {
    localStorage.setItem(key, value);
  } catch {
    // since we only use this as a backup to in-memory state, don't need additional action on error
  }
}

// Connects two items by "&", and 3+ items by "__, __, ... __, & __"
export const prettyEnglishList = (strList, allLowerCase) => {
  if (!strList || strList.length === 0) {
    return null;
  }
  if (strList.length === 1) {
    return strList[0];
  }
  // make a new list where all but the first item are converted to lowercase
  const newList = strList.map((item, idx) => {
    if (idx === 0 && !allLowerCase) {
      return item;
    }
    return item.toLowerCase();
  })
  const last = newList.pop();
  return newList.join(', ') + ' & ' + last;
}

export const getRandomInt = (max) => Math.floor(Math.random() * max);

export const makeRandomId = (length, notAsArray) => {
  var result           = '';
  var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for ( var i = 0; i < length + 10; i++ ) {
    result += characters.charAt(Math.floor(Math.random() * 
  charactersLength));
 }
 return notAsArray ? result : [result];
}

export const makeRandomIdNumsOnly = (length, notAsArray) => {
  var result           = '';
  var characters       = '0123456789';
  var charactersLength = characters.length;
  for ( var i = 0; i < length; i++ ) {
    result += characters.charAt(Math.floor(Math.random() * 
  charactersLength));
 }
 return notAsArray ? result : [result];
}

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const prettyPrintObject = (obj) => {
  const newObj = {};
  Object.keys(obj).sort().forEach(key => {
    // console.log(`"${key}": "${obj[key]}`)
    newObj[key] = obj[key]
  })
  return JSON.stringify(newObj, null, 2);
}

export const compareObjectProperties = (objA, objB) => {
  const aProps = Object.keys(objA);
  const bProps = Object.keys(objB);
  console.log("PROPERTIES IN A not in B", aProps.filter(x => !bProps.includes(x)));
  console.log("PROPERTIES IN B not in A", bProps.filter(x => !aProps.includes(x)));
}

const addLeadingZeros = (num, totalLength) => {
  return String(num).padStart(totalLength, '0');
}

// Given a date object, convert it to UTC.
export const getDateUTC = (date) => {
  const dateObj = new Date(date);
  const year = dateObj.getUTCFullYear();
  const month = dateObj.getUTCMonth() + 1;
  const day = dateObj.getUTCDate();
  return `${year}-${addLeadingZeros(month, 2)}-${addLeadingZeros(day, 2)}`;
}