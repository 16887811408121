import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import {
  Field,
  FastField,
  useFormikContext,
  useField,
  FieldArray,
} from 'formik'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import CreatableSelect from 'react-select/creatable'
import Select from 'react-select'
import closeIcon from '../assets/icons/close.svg'
import infoIcon from '../assets/icons/info.svg'
import {
  ERROR,
  GREY_LIGHT,
  GREY_DARK,
  GREY_LIGHTEST,
  GREY_MEDIUM_LIGHT,
  MAX_DESKTOP_WIDTH,
  PRIMARY_LIGHT,
  GREY_MEDIUM,
  GREY_MEDIUM_DARK,
} from '../constants/cssVars'
import { Tooltip } from './general/Tooltip'
import {
  getOptionFromValue,
  getOptionsFromList,
  softwareInfoByDistributor,
} from '../constants/formOptions'
import { LinkLikeButton } from './Button'

const SHORT_WIDTH = 300

const ErrorText = styled.label`
  margin-bottom: 2px;
  font-size: 0.9em;
  display: block;
  color: ${ERROR};
  font-style: italic;
`

const LabelledInput = styled.div`
  & > label {
    color: ${GREY_DARK};
    margin-bottom: 2px;
    padding-bottom: ${({ smallerText }) => (smallerText ? '1' : '0')}px;
    font-size: ${({ smallerText }) => (smallerText ? '0.8' : '0.9')}em;
    display: inline-block;
    ${({ error }) => (error ? `color: ${ERROR};` : '')}
  }
  & > div > input,
  & > div > select {
    background-color: ${({ disabled }) => (disabled ? GREY_LIGHT : '#fff')};
    color: ${({ disabled }) => (disabled ? GREY_MEDIUM_DARK : '#000')};
    width: ${({ short }) => (short ? `${SHORT_WIDTH}px` : '100%')};
  }
  margin: 5px 0px;
  margin-bottom: 15px;
`

const InputWrapper = styled.div`
  margin: 4px 0px;
  margin-bottom: 2px;
`

const DeleteBtn = styled.button`
  background: transparent;
  border: none;
`

const DeleteIcon = styled.img`
  width: 15px;
  height: 15px;
`

const InfoIcon = styled.img`
  display: inline-block;
  width: 15px;
  height: 15px;
  margin-left: 5px;
  position: relative;
  top: 2px;
`

export const DeleteButton = ({ onDelete }) => (
  <DeleteIcon onClick={onDelete} src={closeIcon} />
)

// Wrapper for field inputs, displaying labels and helper text
export const FieldWrapper = ({
  label,
  field,
  children,
  style,
  disabled,
  inputStyle,
  short,
  error,
  onDelete,
  hideLabel,
  id,
  info,
}) => {
  const { required } = field || {}
  return (
    <LabelledInput
      style={style}
      short={short}
      disabled={disabled}
      error={error}
    >
      {!hideLabel && (
        <label htmlFor={id}>
          {label}
          {field && required ? '*' : ''}
        </label>
      )}
      {info && (
        <Tooltip content={info}>
          <InfoIcon src={infoIcon} alt="more information" />
        </Tooltip>
      )}
      <InputWrapper style={inputStyle}>
        {children}
        {onDelete && <DeleteButton onDelete={onDelete} />}
      </InputWrapper>
      {error && (
        <label id={`errorMsg_${id}`} style={{ fontStyle: 'italic' }}>
          {error}
        </label>
      )}
    </LabelledInput>
  )
}

export const TextField = ({ name, placeholder, disabled, ...props }) => (
  <FieldWrapper {...props} disabled={disabled} id={name}>
    <FastField
      id={name}
      onKeyUp={props.onKeyUp}
      disabled={disabled}
      name={name}
      placeholder={placeholder}
      aria-describedby={`errorMsg_${name}`}
      type={props.type}
      aria-invalid={props.error ? 'true' : 'false'}
    />
  </FieldWrapper>
)

export const SliderField = ({ name, placeholder, ...props }) => (
  <FieldWrapper {...props} id={name}>
    <Field
      className="slider"
      id={name}
      name={name}
      placeholder={placeholder}
      aria-describedby={`errorMsg_${name}`}
      type="range"
      aria-invalid={props.error ? 'true' : 'false'}
      max={props.max}
      min={props.min}
      step={props.step}
      list={props.list}
    />
  </FieldWrapper>
)

const FileUploadWrapper = styled.div`
  padding: 6px 6px;
  border-radius: 4px;
  border: 1px solid ${GREY_MEDIUM};
  background-color: ${GREY_LIGHT};
`

export const FileUploadField = (props) => {
  return (
    <FieldWrapper {...props} id={props.name}>
      <FileUploadWrapper>
        <input type="file" onChange={props.onFileChange} />
        {props.children}
      </FileUploadWrapper>
    </FieldWrapper>
  )
}

export const ParagraphTextField = ({ name, placeholder, ...props }) => (
  <FieldWrapper {...props} id={name}>
    <Field
      id={name}
      as="textarea"
      name={name}
      placeholder={placeholder}
      aria-describedby={`errorMsg_${name}`}
      aria-invalid={props.error ? 'true' : 'false'}
    />
  </FieldWrapper>
)

export const SelectField = (props) => {
  const { setFieldValue, values } = useFormikContext()
  const handleChange = (newValue) => {
    props.onChange && props.onChange(newValue.value)
    setFieldValue(props.name, newValue.value)
    // if (props.name === 'softwareName' && softwareInfoByDistributor[newValue.value]) {
    //   setFieldValue('softwareDistributionURL', softwareInfoByDistributor[newValue.value].distributionUrl);
    //   setFieldValue('softwareDistributor', softwareInfoByDistributor[newValue.value].distributor);
    // }
  }

  const optionSelector = props.optionSelector || getOptionFromValue

  let currentValue = values[props.name]
  if (props.name.includes('contacts.')) {
    const contactNameArray = props.name.split('.')
    const contactIndex = contactNameArray[1]
    currentValue = values.contacts[contactIndex][contactNameArray[2]]
  }

  useEffect(() => {
    if (optionSelector(currentValue) != null) {
      if (optionSelector(currentValue).value !== values[props.name]) {
        setFieldValue(props.name, optionSelector(currentValue).value)
      }
    }
  }, [optionSelector(currentValue)])

  return (
    <FieldWrapper {...props}>
      <Select
        defaultValue={optionSelector(currentValue)}
        className="custom-react-select"
        classNamePrefix="select"
        value={optionSelector(currentValue)}
        onChange={handleChange}
        options={props.options}
      />
    </FieldWrapper>
  )
}

export const MultiSelectField = (props) => {
  const { setFieldValue, values } = useFormikContext()
  const handleChange = (newValue) => {
    const listOfValues = newValue.map((obj) => obj.value)
    setFieldValue(props.name, listOfValues)
  }

  const optionSelector = props.optionSelector || getOptionsFromList

  return (
    <FieldWrapper {...props}>
      <Select
        isMulti
        defaultValue={optionSelector(values[props.name])}
        value={optionSelector(values[props.name])}
        className="custom-react-select"
        classNamePrefix="select"
        onChange={handleChange}
        options={props.options}
      />
    </FieldWrapper>
  )
}

export const DatepickerField = (props) => {
  const { setFieldValue, values } = useFormikContext()
  const handleChange = (newValue) => {
    setFieldValue(props.name, moment(newValue).format('YYYY-MM-DD'))
  }

  const dateValue = values[props.name]
    ? new Date(
        `${values[props.name]}${values[props.name].length > 10 ? '' : ' 00:00'}`
      )
    : null

  return (
    <FieldWrapper {...props}>
      <DatePicker selected={dateValue} onChange={handleChange} />
    </FieldWrapper>
  )
}

const RadioButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 13px 0px;
  margin-right: 20px;
`

const radioInputStyle = {
  backgroundColor: GREY_LIGHTEST,
  padding: '3px 8px',
  borderRadius: '10px',
  marginTop: '8px',
  border: `1px solid ${GREY_MEDIUM_LIGHT}`,
  display: 'block',
}

export const RadioButtonField = ({ name, options, ...props }) => (
  <FieldWrapper {...props} inputStyle={radioInputStyle}>
    {options.map((option) => (
      <RadioButtonWrapper key={option.value}>
        <Field
          id={name}
          style={{ width: 'auto' }}
          type="radio"
          value={option.value}
          name={name}
          aria-describedby={`errorMsg_${name}`}
          aria-invalid={props.error ? 'true' : 'false'}
        />
        <div style={{ marginLeft: '5px' }}>{option.label}</div>
      </RadioButtonWrapper>
    ))}
  </FieldWrapper>
)

const CheckboxWrapper = styled.label`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  ${({ wrapperStyle }) => wrapperStyle};
  margin: 10px 0px;
  margin-right: 15px;
`

const Checkbox = ({
  name,
  value,
  error,
  testId,
  label,
  children,
  wrapperStyle,
}) => (
  <CheckboxWrapper wrapperStyle={wrapperStyle}>
    <Field
      id={name}
      data-testid={testId}
      className="regular-checkbox"
      type="checkbox"
      name={name}
      value={value}
      aria-describedby={`errorMsg_${name}`}
      aria-invalid={error ? 'true' : 'false'}
    />
    {children}
    {label}
  </CheckboxWrapper>
)

export const CheckboxField = ({ error, ...props }) => (
  <div>
    <Checkbox {...props} />
    {error && <ErrorText>{error}</ErrorText>}
  </div>
)

const CheckboxGroupWrapper = styled.div`
  background-color: ${GREY_LIGHTEST};
  padding: 3px 8px;
  border-radius: 10px;
  margin-top: 8px;
  border: 1px solid ${GREY_MEDIUM_LIGHT};
`

export const CheckboxGroup = ({
  checkboxOptions,
  name,
  error,
  labelId,
  label,
  style,
  checkboxStyle,
}) => (
  <LabelledInput error={error}>
    <label id={labelId}>{label}</label>
    <CheckboxGroupWrapper
      role="group"
      aria-labelledby={labelId}
      style={style}
      aria-describedby={`errorMsg_${name}`}
      aria-invalid={error ? 'true' : 'false'}
    >
      {checkboxOptions.map((props) => (
        <Checkbox
          {...props}
          name={name}
          wrapperStyle={checkboxStyle}
          key={props.value}
        />
      ))}
    </CheckboxGroupWrapper>
    {error && <ErrorText>{error}</ErrorText>}
  </LabelledInput>
)

export const MultiFieldLine = styled.div`
  width: 100%;
  width: calc(100% - 15px);
  display: grid;
  grid-column-gap: 15px;
  grid-template-columns: ${({ columns }) => columns};
  @media only screen and (max-width: ${MAX_DESKTOP_WIDTH}px) {
    display: flex;
    flex-direction: column;
    > div {
      max-width: 400px;
    }
  }
`

export const OptionsList = ({ hideSelectOne, options, name }) => {
  const fullOptions = [
    ...(hideSelectOne ? [] : [{ value: '', label: 'Select One' }]),
    ...options,
  ]
  return fullOptions.map((option) => (
    <option key={`${name}_${option.value}`} value={option.value}>
      {option.label}
    </option>
  ))
}

// If we need to make this on-outside-click create: https://github.com/JedWatson/react-select/issues/1764
export const CreatableMulti = (props) => {
  const { setFieldValue, values } = useFormikContext()

  const handleChange = (newValue) => {
    const listOfValues = newValue.map((obj) => obj.value)
    setFieldValue(props.name, listOfValues)
  }

  return (
    <FieldWrapper {...props} id={props.name}>
      <CreatableSelect
        defaultValue={getOptionsFromList(values[props.name])}
        className="custom-react-select"
        isMulti={true}
        onChange={handleChange}
        options={props.options}
      />
    </FieldWrapper>
  )
}

const components = {
  DropdownIndicator: null,
}

const createOption = (label) => ({
  label,
  value: label,
})

export const CreatableInputOnly = (props) => {
  const { setFieldValue, values } = useFormikContext()
  const [inputValue, setInputValue] = useState('')
  // Split comma-separated string into an array
  // Check if values[props.name] is a string before splitting it
  const valueArray =
    typeof values[props.name] === 'string'
      ? values[props.name].split(',').map((value) => value.trim())
      : []

  const [value, setValue] = useState(getOptionsFromList(valueArray))

  useEffect(() => {
    const listOfValues = value.map((obj) => obj.value)
    setFieldValue(props.name, listOfValues)
  }, [value])

  const handleChange = (value) => {
    setValue(value)
  }

  const handleBlur = () => {
    const label = inputValue?.trim() || ''
    // const optionExists = options.find((opt) => opt.label === label);

    if (!label) {
      return
    }

    const option = { label, value: label }

    // // Add new option to menu list
    // setOptions([...options, option]);
    // Add value to selected options
    setValue([...(value || []), option])
    // Clear input value
    setInputValue('')
  }

  const handleInputChange = (textInput, { action }) => {
    if (action === 'input-change') {
      setInputValue(textInput)
    }
    if (action === 'input-blur') {
      handleBlur()
    }
  }

  const handleKeyDown = (event) => {
    if (!inputValue) return
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        const newValues = [...value, createOption(inputValue)]
        setInputValue('')
        setValue(newValues)
        event.preventDefault()
    }
  }

  return (
    <FieldWrapper {...props} id={props.name}>
      <CreatableSelect
        components={components}
        inputValue={inputValue}
        className="custom-react-select"
        isClearable
        isMulti
        menuIsOpen={false}
        onChange={handleChange}
        onInputChange={handleInputChange}
        onKeyDown={handleKeyDown}
        placeholder="Type and press enter..."
        value={value}
      />
    </FieldWrapper>
  )
}

const MultiDatePickerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
`

export const DatepickerRangeField = (props) => {
  const { setFieldValue, values } = useFormikContext()
  const handleChange = (newValue, index) => {
    setFieldValue(`${props.name}.${index}`, newValue)
  }

  return (
    <FieldWrapper {...props}>
      <FieldArray
        name={props.name}
        render={(arrayHelpers) => (
          <div>
            {values[props.name].map((friend, index) => {
              const isArray =
                values[props.name] &&
                Array.isArray(values[props.name][index]) &&
                values[props.name][index].length > 0
              // const valuesAreInitialized = Array.isArray(values[props.name]) && Array.isArray(values[props.name][index]) && values[props.name].length > 1;
              const startDate = isArray ? values[props.name][index][0] : null
              const endDate = isArray ? values[props.name][index][1] : null

              return (
                <MultiDatePickerWrapper key={index}>
                  <DatePicker
                    selected={startDate}
                    onChange={(update) => {
                      handleChange([update, endDate], index)
                    }}
                  />
                  <div style={{ margin: '0px 5px' }}>to</div>
                  <DatePicker
                    selected={endDate}
                    onChange={(update) => {
                      handleChange([startDate, update], index)
                    }}
                  />
                  <DeleteBtn
                    type="button"
                    onClick={() => arrayHelpers.remove(index)}
                  >
                    <DeleteIcon src={closeIcon} />
                  </DeleteBtn>
                </MultiDatePickerWrapper>
              )
            })}
            {/* <div style={{ fontSize: '0.9em', fontStyle: 'italic'}}>Temporarily disabling multiple time period inputs</div> */}
            <div style={{ textAlign: props.right ? 'right' : 'left' }}>
              <LinkLikeButton
                type="button"
                onClick={() => arrayHelpers.push([])}
              >
                + Add another
              </LinkLikeButton>
            </div>
          </div>
        )}
      />
    </FieldWrapper>
  )
}
